.wrapper {
    width: 100vw;
    height: 100vh;
    background-image: url("../../assets/images/pages/wave.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    object-fit: cover;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-card {
    width: 400px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    border-top: 5px solid;
    border-color: rgb(233, 83, 170);
    /* color:rgb(163, 156, 156); */
    box-shadow: 0 0 5px;
    animation: borderAnimation 800ms infinite alternate;
}

@keyframes borderAnimation {
    0% {
        border-color: rgb(233, 83, 170);
    }

    50% {
        border-color: rgb(170, 35, 114);
    }

    100% {
        border-color: rgb(233, 83, 170);
    }
}


.inputBox {
    padding-left: 1.5em;
}

.textStyle {
    font-size: 1.5em;
    font-weight: 600;
    color: #616161;
    margin-bottom: 1em;
}

@media (max-width: 576px) {
    .otp-card {
        width: 800px !important;

    }

    .inputBox {
        padding-left: 1em;
    }


}

@media (max-width: 768px) {
    .otp-card {
        width: 500px !important;
    }
}