  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  /* .ant-modal-header {
    border-radius: 7px 7px 0 0 !important;
    background-color: #01579b !important;
    padding: 8px 0px !important;
    background-color: #e5e8ee !important;
    background-image: linear-gradient(rgb(224, 227, 233), rgb(233, 237, 241)) !important;
  } */


  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    /* color: white; */
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .ant-modal-header {
    border-radius: 7px 7px 0 0 !important;
    /* background-color: #01579b !important; */
    /* padding: 8px 0px !important; */
    background-color: #fff !important;
  }

  .ant-modal-content {
    border-radius: 7px !important;

  }

  .ant-modal-close-x {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* font-size: 1.3rem !important; */
    /* align-self: center !important; */
    /* vertical-align: middle !important; */
    transition: all 0.5ms ease-in-out;
  }
  .ant-modal-close-x:hover {
    
    color: red !important;
  }

  .ant-input {
    border-radius: 5px !important;
  }

  .ant-select-selector {
    border-radius: 5px !important;

  }

  .ant-select-selection-placeholder {
    color: #c7c7c9 !important;
  }

  .ant-btn-lg {

    border-radius: 5px !important;
  }

  .zoomBtns {
    /* padding: 50px;
  background-color: green; */
    transition: transform .2s;
    /* Animation */
    /* width: 200px;
  height: 200px; */
    /* margin: 0 auto; */
  }

  .zoomBtns:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .fixedFooter {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: block;
    padding: 0;
    overflow-x: hidden;
    background-color: transparent;
    box-shadow: 0 1px 8px rgba(0, 21, 41, 0.15);
    transition: width 0.2s;

  }